// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
//@import '~css-star-rating/scss/star-rating';
@import '~css-star-rating/css/star-rating.min.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$serdo-primary: mat-palette($mat-amber, 700);
$serdo-accent: mat-palette($mat-yellow, 300, 200, 400);

// The warn palette is optional (defaults to red).
$serdo-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$serdo-theme: mat-light-theme($serdo-primary, $serdo-accent, $serdo-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

$serdo-dark-theme: mat-dark-theme($serdo-primary, $serdo-accent, $serdo-warn);

@include angular-material-theme($serdo-theme);
@import '~ngx-sharebuttons/themes/default/default-theme';
//@include angular-material-theme($serdo-dark-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}
// Color classses
.primary{
  color: darken(mat-color($serdo-primary), 15%)
}
.primary-bg{
  background: mat-color($serdo-primary);
}
.accent{
  color: mat-color($serdo-accent);
}
.accent-bg{
  background: mat-color($serdo-accent);
}
.secondery, .secondary{
  color: mat-color($mat-blue-gray, 500);
}
// Overdid styles
.mat-flat-button.mat-primary {
  text-shadow: 0 0 24px rgba(0,0,0,.3);
  background-image:linear-gradient(
      to left top,mat-color($serdo-primary),mat-color($serdo-primary, 300));
  color: white;
}
.mat-flat-button.mat-primary:hover  { background-position: right center; }
.mat-dialog-container{
  overflow-x: hidden !important;
}
.disabled-header .mat-horizontal-stepper-header {
  pointer-events: none !important;
}
.mat-step-icon-content{
  color: white;
}
.mat-step-header .mat-step-icon .mat-step-icon-selected{
  color: white
}
.mat-checkbox-checked .mat-checkbox-inner-container{
  @extend .primary-bg;
  border-width: 1px;
}
.mat-link{
  color: blue;
}
.mat-pseudo-checkbox-checked::after{
  color: mat-color($serdo-primary)
}

@media (max-width: 910px){
  .mat-horizontal-stepper-header{
    display: flex;
    flex-flow: column;
  }
  .mat-step-label{
    font-size: .9em;
  }
}

// Common styling classes
.flex{
  display: flex;
}
.flex-col{
  display: flex;
  flex-flow: column;
}
.flex-row{
  display: flex;
  flex-flow: row;
}
.loading-form{
  opacity: 0.4;
  pointer-events: none;
  cursor: wait;
}
.capitalize{
  text-transform: capitalize;
}
.flat-card{
   padding: 1em;
   border: 1px solid #efefef;
   border-radius: 5px;
   background-color: white;
 }
@keyframes loading-animation {
  0%   {
    background-image:
      linear-gradient(
          to right,
          rgba(250,250, 250, 1),
          rgba(240,240, 240, 1) 0%
      );
  }
  25%  {
    background-image:
      linear-gradient(
          to right,
          rgba(250,250, 250, 1),
          rgba(240,240, 240, 1) 25%
      );
  }
  50%  {
    background-image:
      linear-gradient(
          to right,
          rgba(250,250, 250, 1),
          rgba(240,240, 240, 1) 50%
      );
  }
  100% {
    background-image:
      linear-gradient(
          to right,
          rgba(250,250, 250, 1),
          rgba(240,240, 240, 1) 100%
      );
  }
}
.progress {
  background-color: rgba(240,240, 240, 1);
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
// ngx gallery
.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}
// font
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Lobster+Two:ital,wght@0,700;1,400&family=Rancho&family=Roboto+Slab:wght@800&display=swap');
.lobster{
  font-family: 'Lobster', cursive;
}
.lobster-two{
  font-family: 'Lobster Two', cursive;
}
.rancho {
  font-family: 'Rancho', cursive;
}
.roboto-slab {
  font-family: 'Roboto Slab', serif;
}
// Customizing angular-star-rating

#fr-logo, .fr-view p:last-child {
  display: none;
}
.fr-wrapper>div:first-child>a{
  display: none;
}
.editor-view td{
  border: 1px solid #4f4f4f;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::ng-deep {
  .custom-slider .ngx-slider .ngx-slider-bar {
    background: #ffe4d1;
    height: 2px;
  }
  .custom-slider .ngx-slider .ngx-slider-selection {
    background: orange;
  }

  .custom-slider .ngx-slider .ngx-slider-pointer {
    width: 8px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: #333;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .custom-slider .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .custom-slider .ngx-slider .ngx-slider-bubble {
    bottom: 14px;
  }

  .custom-slider .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: orange;
  }

  .custom-slider .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
  }

  .custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: orange;
  }
}
